<!-- eslint-disable prettier/prettier -->
<template>
  <v-responsive>
    <v-card outlined>
      <v-card outlined class="mb-4">
        <v-row class="margin-row">
          <v-col class="dialog-padding">
            <label>集合時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="startTime"
              manual-input
              auto-scroll
              input-width="95%"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
          <v-col class="dialog-padding">
            <label>開始時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="startTime1"
              manual-input
              auto-scroll
              input-width="95%"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
          <v-col class="dialog-padding">
            <label>終了時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="endTime"
              manual-input
              auto-scroll
              input-width="95%"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="dialog-padding" lg="4">
            <label>期社時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="startTime2"
              manual-input
              auto-scroll
              input-width="95%"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
          <v-col class="ml-7" cols="auto">
            <v-checkbox v-model="greeting" label="あいさつ"></v-checkbox>
          </v-col>
          <v-col cols="auto">
            <v-checkbox v-model="safety" label="安全確認"></v-checkbox>
          </v-col>
          <v-col cols="auto">
            <v-checkbox v-model="displayBoard" label="表示板"></v-checkbox>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              v-model="coneChain"
              label="コーン+チェーン"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="ml-7 mr-7">
          <v-text-field label="その他" outlined></v-text-field>
        </v-row>
      </v-card>
      <v-card outlined v-for="(card, index) in cards" :key="index" class="mb-4">
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" sm="8">
              <v-card-title>{{ card.personInChargeOfWork }}</v-card-title>
            </v-col>
          </v-row>

          <v-row dense class="margin-row">
            <v-col cols="">
              <v-text-field
                label="作業担当者"
                outlined
                v-model="card.name"
                clearable
              />
            </v-col>
            <v-col class="ml-5" cols="4">
              <label>休憩時間①</label>
              <v-row no-gutters>
                <v-col class="timepicker">
                  <vue-timepicker
                    class="ml-7"
                    v-model="card.breakTime1From"
                    manual-input
                    auto-scroll
                    input-width="100%"
                  >
                  </vue-timepicker>
                </v-col>
                <span style="margin: auto; text-align: center;">～ </span>

                <v-col class="timepicker-right">
                  <vue-timepicker
                    v-model="card.breakTime1To"
                    manual-input
                    auto-scroll
                    input-width="100%"
                  >
                  </vue-timepicker>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="ml-5">
              <label>休憩時間②</label>
              <v-row no-gutters>
                <v-col class="timepicker">
                  <vue-timepicker
                    class="ml-7"
                    v-model="card.breakTime2From"
                    manual-input
                    auto-scroll
                    input-width="100%"
                  >
                  </vue-timepicker>
                </v-col>
                <span style="margin: auto; ">～ </span>

                <v-col class="timepicker-right">
                  <vue-timepicker
                    v-model="card.breakTime2To"
                    manual-input
                    auto-scroll
                    input-width="100%"
                  >
                  </vue-timepicker>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="実休憩時間"
                outlined
                v-model="card.restTime"
                suffix="H"
              ></v-text-field>
            </v-col>
            <v-col class="ml-7">
              <v-checkbox
                label="時間計算"
                v-model="card.timeCalculation"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-text-field
                label="作業時間"
                outlined
                v-model="card.workingTime"
                suffix="H"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="残業時間"
                outlined
                v-model="card.overTime"
                suffix="H"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-row dense>
        <v-col>
          <v-text-field
            label="担当者変更内容"
            outlined
            v-model="detailOfChange"
            clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field
            label="担当者変更理由"
            outlined
            v-model="reasonForChange"
            clearable
          />
        </v-col>
      </v-row>
    </v-card>
  </v-responsive>
</template>
<!-- eslint-disable prettier/prettier -->

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    "vue-timepicker": VueTimepicker
  },
  data() {
    return {
      startTime: "08:30",
      startTime1: "08:30",
      startTime2: "08:30",
      endTime: "15:30",
      greeting: true,
      safety: true,
      displayBoard: true,
      coneChain: false,
      cards: [
        {
          personInChargeOfWork: "作業担当者",
          name: "大阪　彰",
          breakTime1From: "12:00",
          breakTime1To: "13:00",
          breakTime2From: "",
          breakTime2To: "",
          restTime: "1:00",
          timeCalculation: true,
          workingTime: "5.00",
          overTime: "0.00"
        },
        {
          personInChargeOfWork: "担当者",
          isChecked: false,
          name: "大阪　彰",
          breakTime1From: "12:00",
          breakTime1To: "13:00",
          breakTime2From: "",
          breakTime2To: "",
          restTime: "1:00",
          timeCalculation: true,
          workingTime: "5.00",
          overTime: "0.00"
        }
      ],
      detailOfChange: "",
      reasonForChange: ""
    };
  }
};
</script>

<style scoped>
.v-card {
  padding: 16px;
}

.timepicker :nth-child(1) {
  border-right: none !important;
  /* text-align: center; */
}
.timepicker-right :nth-child(1) {
  border-left: none !important;
  text-align: center;
  width: max-content;
}
</style>
