<!-- eslint-disable prettier/prettier -->
<template>
  <v-responsive>
    <v-card outlined>
      <v-card outlined v-for="(card, index) in cards" :key="index" class="mb-4">
        <v-container fluid>
          <v-row dense>
            <v-col lg="10" sm="9">
              <v-text-field
                label="作業内容"
                outlined
                v-model="card.reason"
                clearable
              />
            </v-col>

            <v-col lg="2" sm="3" class="d-flex justify-end">
              <v-checkbox
                v-model="card.isChecked"
                label="追加・修正"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6">
              <v-text-field
                label="フロア"
                outlined
                v-model="card.floor"
                clearable
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="エリア"
                outlined
                v-model="card.area"
                clearable
              />
            </v-col>
          </v-row>

          <!-- <v-row dense> -->
          <v-row
            dense
            v-for="(passage, passageIndex) in card.passages"
            :key="'passage-' + passageIndex"
            no-gutters
          >
            <v-col md="4" sm="3" lg="5" xl="3">
              <v-text-field
                label="所"
                outlined
                v-model="passage.name"
                clearable
              />
            </v-col>

            <!-- <v-col md="8" sm="9" lg="7" xl="8"> -->
            <v-row
              md="8"
              sm="8"
              lg="7"
              class=" d-flex justify-space-between ml-2 "
            >
              <v-col
                v-for="(checkbox, checkboxIndex) in passage.checkboxes"
                :key="'checkbox-' + checkboxIndex"
                cols="auto"
              >
                <v-checkbox
                  v-model="checkbox.checked"
                  :label="checkbox.label"
                />
              </v-col>
            </v-row>
            <!-- </v-col> -->
          </v-row>
          <!-- </v-row> -->
        </v-container>
      </v-card>

      <v-row dense>
        <v-col cols="3">
          <v-checkbox
            label="病院様より要請"
            v-model="requestFromHospital"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox label="自社都合" v-model="inHouse"></v-checkbox>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="病院担当者"
            outlined
            v-model="hospitalPersonel"
            clearable
          />
        </v-col>
        <v-col cols="6">
          <v-menu
            v-model="menu"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateOfSchedule"
                label="日程検討日"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="workDt"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field
            label="病院側残理由"
            outlined
            v-model="reasonForLeavingHospital"
            clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field
            label="自社側残理由"
            outlined
            v-model="reasonForRemainingOnACommpanySides"
            clearable
          />
        </v-col>
      </v-row>
    </v-card>
  </v-responsive>
</template>
<!-- eslint-disable prettier/prettier -->

<script>
export default {
  data() {
    return {
      cards: [
        {
          reason: "床面WAX洗浄",
          isChecked: true,
          floor: "2F",
          area: "OPEエリア",
          passages: [
            {
              name: "Ａ室",
              checkboxes: [
                { label: "済", value: "済", checked: true },
                { label: "次月", value: "次月", checked: false },
                { label: "検討", value: "検討", checked: false },
                { label: "次回", value: "次回", checked: false },
                { label: "追加・修正", value: "追加・修正", checked: false }
              ]
            },
            {
              name: "Ｂ室",
              checkboxes: [
                { label: "済", value: "済", checked: true },
                { label: "次月", value: "次月", checked: false },
                { label: "検討", value: "検討", checked: false },
                { label: "次回", value: "次回", checked: false },
                { label: "追加・修正", value: "追加・修正", checked: false }
              ]
            }
          ]
        },
        {
          reason: "床面WAX洗浄",
          isChecked: false,
          floor: "1F",
          area: "共用エリア",
          passages: [
            {
              name: "待合室",
              checkboxes: [
                { label: "済", value: "済", checked: true },
                { label: "次月", value: "次月", checked: false },
                { label: "検討", value: "検討", checked: false },
                { label: "次回", value: "次回", checked: false },
                { label: "追加・修正", value: "追加・修正", checked: false }
              ]
            },
            {
              name: "廊下",
              checkboxes: [
                { label: "済", value: "済", checked: false },
                { label: "次月", value: "次月", checked: true },
                { label: "検討", value: "検討", checked: false },
                { label: "次回", value: "次回", checked: false },
                { label: "追加・修正", value: "追加・修正", checked: false }
              ]
            }
          ]
        }
      ],
      hospitalPersonel: "山田 太郎",
      dateOfSchedule: "2025/03/10",
      reasonForLeavingHospital: "床の破損による、工事のため",
      reasonForRemainingOnACommpanySides: "",
      requestFromHospital: true,
      inhouse: false
    };
  }
};
</script>

<style scoped>
.v-card {
  padding: 10px;
}
</style>
