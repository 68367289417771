<!-- eslint-disable prettier/prettier -->
<template>
  <v-form>
    <v-card outlined>
      <v-container fluid>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="name"
              outlined
              label="物件名"
              clearable
            ></v-text-field>
          </v-col>

          <v-col style="text-align: right" cols="6">
            <v-menu
              v-model="menu"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="workDt"
                  label="作業実施日"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="workDt"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col cols="2">
            <v-card outlined class="d-flex justify-center align-center">
              <v-btn
                large
                elevation="0"
                @click="onForm1Click"
                :color="isForm1Open ? `blue` : ``"
                block
                :style="isForm1Open ? { color: 'white' } : {}"
                >作業内容</v-btn
              >
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined class="d-flex justify-center align-center">
              <v-btn
                large
                elevation="0"
                @click="onForm2Click"
                :color="isForm2Open ? `blue` : ``"
                block
                :style="isForm2Open ? { color: 'white' } : {}"
                >作業者</v-btn
              >
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <form-1 v-if="isForm1Open" ref="form1"></form-1>
          <form-2 v-if="isForm2Open" ref="form2"></form-2>
        </v-row>

        <v-row>
          <v-col cols="8">
            <v-card elevation="0" width="max-content">
              <v-card-title st>
                電子署名
              </v-card-title>
              <canvas
                @mousedown="startDrawing"
                @mousemove="drawSignature"
                @mouseup="stopDrawing"
                @mouseleave="stopDrawing"
                @touchstart="startDrawing"
                @touchmove="drawSignature"
                @touchend="stopDrawing"
                @touchcancel="stopDrawing"
                ref="signatureCanvas"
              ></canvas>
            </v-card>
          </v-col>

          <v-col
            class="d-flex justify-end align-center"
            style="align-self: end;"
          >
            <v-btn @click="clearCanvas" color="error" class="mr-2" large
              >閉じる</v-btn
            >
            <v-btn @click="saveSignature" color="success" large>完了</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import Form1 from "./components/Form1.vue";
import Form2 from "./components/Form2.vue";
export default {
  name: "Completion Report",
  components: { Form1, Form2 },
  data() {
    return {
      menu: false,
      name: "〇×病院",
      workDt: "",
      isForm1Open: true,
      isForm2Open: false,
      isDrawing: false,
      lastPos: { x: 0, y: 0 },
      mousePos: { x: 0, y: 0 },
      signatureData: null,
      electronicSignature: "山田"
    };
  },
  methods: {
    post() {
      console.log("Form submitted");
    },
    onForm1Click() {
      this.isForm1Open = true;
      this.isForm2Open = false;
    },
    onForm2Click() {
      this.isForm1Open = false;
      this.isForm2Open = true;
    },
    getPosition(event) {
      // Calculate position for both touch and mouse events
      const rect = this.$refs.signatureCanvas.getBoundingClientRect();
      const x = event.touches ? event.touches[0].clientX : event.clientX;
      const y = event.touches ? event.touches[0].clientY : event.clientY;
      return { x: x - rect.left, y: y - rect.top };
    },
    startDrawing(event) {
      event.preventDefault();
      this.mousePos = this.getPosition(event);
      this.isDrawing = true;
      this.lastPos = { ...this.mousePos };
    },
    drawSignature(event) {
      if (!this.isDrawing) return;

      const canvas = this.$refs.signatureCanvas;
      const ctx = canvas.getContext("2d");
      const { x, y } = this.getPosition(event);

      ctx.strokeStyle = "#222222";
      ctx.lineWidth = 2;
      ctx.lineJoin = "round";
      ctx.lineCap = "round";

      ctx.beginPath();
      ctx.moveTo(this.lastPos.x, this.lastPos.y);
      ctx.lineTo(x, y);
      ctx.stroke();

      this.lastPos = { x, y };
    },
    stopDrawing(event) {
      event.preventDefault();
      this.isDrawing = false;
    },
    clearCanvas() {
      const canvas = this.$refs.signatureCanvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.signatureData = null;
    },
    saveSignature() {
      const canvas = this.$refs.signatureCanvas;
      this.signatureData = canvas.toDataURL();
      console.log("Signature", this.signatureData);
      this.clearCanvas();
    }
  }
};
</script>

<style scoped>
.v-card {
  padding: 1em;
}
canvas {
  border: 1px dotted #524f4f;
  cursor: crosshair;
}
</style>
