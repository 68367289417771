<template>
  <v-container fluid>
    <v-card outlined class="pa-4" flat style=" margin: 0 auto;">
      <h1 class="mb-4" style="font-size: 24px;">見積内容登録</h1>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-text-field
            label="見積番号"
            v-model="id"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-radio-group v-model="row" row>
        <v-radio label="定期" value="radio-1"></v-radio>
        <v-radio label="スポット" value="radio-2"></v-radio>
      </v-radio-group>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-text-field
            label="病院"
            v-model="hospital"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-text-field
            label="請求金額"
            v-model="invoiceAmount"
            type="number"
            outlined
            suffix="円"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            label="作業内容"
            v-model="reason"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn color="success" @click="onSubmit" class="ml-4" elevation="2">
            <v-icon left>mdi-check</v-icon> 完了
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      id: "49073",
      hospital: "〇×病院",
      invoiceAmount: "126000",
      reason: "消防訓練立会い",
      row: "radio-2"
    };
  },
  methods: {
    onSubmit() {
      this.id = "";
      this.hospital = "";
      this.invoiceAmount = "";
      this.reason = "";
    }
  }
};
</script>

<style scoped>
h1 {
  margin-bottom: 16px;
  font-size: 24px;
}
</style>
