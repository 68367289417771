<template>
  <v-card outlined>
    <!-- <h1 class="h2">{{ Title }}</h1> -->
    <h1 class="h2">パッケージ登録</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace">戻る</Btn>
      <Btn class="ml-3" color="info" icon="details">編集</Btn>
      <Btn class="ml-3" color="warning" icon="toy-brick-plus-outline"
        >見積参照</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <!-- <v-autocomplete
          v-model="selecthospital"
          :items="hospitals"
          label="病院"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-hospital-building"
          return-object
        ></v-autocomplete> -->
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              label="病院"
              v-model="hospital"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field label="見積番号" v-model="id" outlined></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-text-field
              label="請求金額"
              v-model="invoiceAmount"
              type="number"
              outlined
              suffix="円"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="10">
            <v-text-field
              label="作業内容"
              v-model="reason"
              type="text"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model.number="packageNo"
          label="パッケージ番号"
          type="number"
          outlined
          prepend-icon="mdi-numeric"
          v-show="false"
        ></v-text-field>
        <v-row class="margin-row">
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="daycount"
              type="number"
              suffix="日"
              outlined
              prepend-icon="mdi-numeric"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <label>開始時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="startTime"
              manual-input
              auto-scroll
              input-width="95%"
              :disabled="!edit"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
          <v-col class="dialog-padding">
            <label>終了時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="endTime"
              manual-input
              auto-scroll
              input-width="95%"
              :disabled="!edit"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
        </v-row>
        <v-row class="margin-row">
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="ninsuu"
              type="number"
              suffix="人"
              outlined
              prepend-icon="mdi-human-queue"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-autocomplete
              v-model="selectcycle.name"
              :items="cycles"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-sync-circle"
            ></v-autocomplete>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="monthcount"
              type="number"
              suffix="回"
              outlined
              prepend-icon="mdi-numeric"
            ></v-text-field>
          </v-col>
        </v-row>
        <DatePicker
          v-model="startdate"
          prepend-icon="mdi-calendar"
          outlined
          :disabled="!edit"
          type="month"
          :rules="[Rules.Required]"
        />
        <v-autocomplete
          v-model="selectFilterCompany"
          :items="filterCompany"
          prepend-icon="mdi-account-cog"
          outlined
          item-text="name"
          item-value="code"
          return-object
          clearable
        ></v-autocomplete>
        <v-text-field
          v-model="weekday"
          outlined
          prepend-icon="mdi-calendar-week"
          maxlength="45"
        ></v-text-field>
        <v-row style="height: 60px">
          <v-col cols="2">
            <v-text-field
              value="指定曜日"
              prepend-icon="mdi-calendar-week"
              readonly
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox v-model="monday" label="月"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="tuesday" label="火"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="wednesday" label="水"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="thursday" label="木"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="friday" label="金"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="saturday"
              label="土"
              color="secondary"
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="sunday" label="日" color="error"> </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="nholiday" label="祝" color="success">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row style="height: 90px">
          <v-col cols="2">
            <v-text-field
              value="指定週"
              prepend-icon="mdi-calendar-week"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week1" label="1週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week2" label="2週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week3" label="3週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week4" label="4週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week5" label="5週目"></v-checkbox>
          </v-col>
        </v-row>
        <v-radio-group
          v-model="selectNationalHoliday"
          row
          label="祝日設定"
          prepend-icon="mdi-toggle-swtich"
        >
          <v-radio
            label="指定曜日祝日対象外"
            :value="SelectsNationalHoliday.No"
          ></v-radio>
          <v-radio
            label="指定曜日祝日対象"
            :value="SelectsNationalHoliday.YesWd"
          ></v-radio>
          <!-- <v-radio
            label="祝日指定"
            :value="selectNationalHoliday.YesHd"
          ></v-radio> -->
        </v-radio-group>
        <!-- <v-row>
          <v-text-field
            v-model="nhoff"
            prepend-icon="mdi-information"
            dense
            readonly
          ></v-text-field>
        </v-row>-->
        <v-row>
          <p
            prepend-icon="mdi-information"
            style="color: red"
            class="text-h7 spacing-playground pa-1"
          >
            <strong>{{ nhoff }}</strong>
          </p>
        </v-row>
        <!-- <v-row>
          <v-text-field
            v-model="nhon"
            prepend-icon="mdi-information"
            dense
            readonly
          ></v-text-field>
        </v-row> -->
        <v-row v-for="(item, i) in meisai" :key="i">
          <v-text-field
            :value="item.display"
            class="mr-5"
            outlined
            dense
            readonly
            prepend-icon="mdi-close"
            @click:prepend="clickDelRow(i)"
            required
            :rules="[Rules.Required]"
          />
          <v-btn
            color="success"
            @click="showRowDialog(i)"
            :disabled="!edit"
            v-if="edit"
          >
            編集</v-btn
          >
        </v-row>
        <v-btn rounded color="primary" small @click="addrow" :disabled="!edit">
          <v-icon>mdi-plus</v-icon> 明細を追加</v-btn
        >

        <v-radio-group
          v-model="selectDelete"
          row
          label="削除フラグ:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success"> 完了 </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const SelectsDelete = {
  Delete: 0,
  Default: 1
};
const SelectsNationalHoliday = {
  No: 0,
  YesWd: 1,
  YesHd: 2
};

export default {
  name: "Package Registration",
  mixins: [Common, Forms, Api, ShowDialogs],
  components: {
    "vue-timepicker": VueTimepicker
  },
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: true,
      hospitals: [],
      selecthospital: { code: "", name: "" },
      packageNo: 1,
      startdate: new Date(2025, 1),
      selectcycle: { code: "1", name: "随時" },
      cycles: [{ name: "随時", value: "1" }],
      monthcount: 2,
      daycount: 1,
      weekday: "曜日備考",
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      nholiday: false,
      week1: false,
      week2: false,
      week3: false,
      week4: false,
      week5: false,
      ninsuu: 2,
      startTime: "08:30",
      endTime: "15:30",
      selectDelete: SelectsDelete.Default,
      SelectsDelete,
      selectNationalHoliday: SelectsNationalHoliday.No,
      SelectsNationalHoliday,
      filterCompany: [{ name: "協力会社", code: "1" }],
      selectFilterCompany: { name: "協力会社", code: "1" },
      meisai: [
        { packageMeisaiNo: "1", display: "'床面WAX洗浄-2F-中央材料室 " }
      ],
      nhoff: "",
      id: "49073",
      hospital: "〇×病院",
      invoiceAmount: "126000",
      reason: "消防訓練立会い"
    };
  },

  methods: {
    addrow() {
      const index = this.meisai.length + 1;
      this.meisai.push({ packageMeisaiNo: index, display: "" });
    },

    async clickDelRow(i) {
      if (this.meisai.workPattern) {
        const msg = `明細 ${i + 1} を削除します。<br>よろしいですか？`;
        if (!(await this.$deleteConfirm(msg))) return;
      }
      this.meisai.splice(i, 1);
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let filter = [];

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.CYCLE);
      this.cycles = filter.filter(e => e.code !== NotSetCode.CYCLE);

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.HLP);
      this.filterCompany = filter.filter(e => e.code !== NotSetCode.HLP);
    },
    getTextIetems() {
      console.log("getTextItems", this.codeMasterItems);
      let textlst = [];
      textlst = this.codeMasterItems.filter(e => e.refcode === CodeGroup.TEXT);
      const text = textlst.filter(e => e.code === "0");
      console.log("text1", text);
      this.nhoff = text[0].option2;
    }
  },
  async created() {
    this.$loading();
    try {
      await this.getCodeMasterItems();
      this.getPullDownItems();

      this.getTextIetems();
    } catch (e) {
      // this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style scoped>
label {
  font-size: 1em;
}
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
.v-card {
  padding: 10px;
}
</style>
